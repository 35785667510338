import { setErrorMsg } from "../redux/reducers/settingsSlice";
import { store } from "../redux/store";
import { Roles } from "../utils/Constants";
import { APP_ACCOUNT_BALANCE_V2_URL } from "../utils/Endpoints";
import { hasRole } from "../utils/Helpers";
import API from "./api";
const { REACT_APP_BASE_URL } = process.env;

/**
 *
 * @returns account balance
 */

export const getAccountBalance = async (callback) => {
  if (!hasRole(Roles.finance)) {
    return;
  }
  await API.get(REACT_APP_BASE_URL + APP_ACCOUNT_BALANCE_V2_URL)
    .then((res) => {
      callback(res.data);
      return res.data;
    })
    .catch((error) => {
      message(
        "ANB account Balance can not be fetched due to service is down!",
        false
      );
      console.warn("Error occurred for balance api: " + error);
    });
};

const message = (data, reload) => {
  store.dispatch(
    setErrorMsg({
      icon: null,
      closeBtn: false,
      acceptBtnTitle: "OK",
      modalContentClass: "small",
      acceptBtnAction: () => {
        if (reload) window.location.reload();
      },
      title: "Status",
      message: data,
    })
  );
};

export interface AccountBalanceModel {
  accountNumber: string;
  balance: number;
  currency: string;
}
