export enum Roles {
  operation = "OPERATION",
  manager = "MANAGER",
  anti_fraud = "ANTI_FRAUD",
  finance = "FINANCE",
  credit = "CREDIT",
  help_desk = "HELP_DESK",
  customer_care = "CUSTOMER_CARE",
  compliance = "COMPLIANCE",
  compliance_manager = "COMPLIANCE_MANAGER",
}

export enum PayoutStatus {
  READY_FOR_NEW = "READY_FOR_NEW",
  ANB_PAYOUT_PROCESSING = "ANB_PAYOUT_PROCESSING",
  PENDING_FOR_APPROVAL = "PENDING_FOR_APPROVAL",
  PENDING_FOR_FINANCE_APPROVAL = "PENDING_FOR_FINANCE_APPROVAL",
  APPROVED_FROM_FINANCE = "APPROVED_FROM_FINANCE",
  REJECTED_FROM_FINANCE = "REJECTED_FROM_FINANCE",
  PROCESSING = "PENDING_FOR_FINANCE_APPROVAL",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",

  //Below status are applicable only for the ANB payment channel type.
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum PaymentProvider {
  ANB_PAY = "ANB_PAY",
  HYPER_PAY = "HYPER_PAY",
}

export enum PaymentStatus {
  ALL = "ALL",
  SUCCESS = "SUCCESS",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  INITIATED = "INITIATED",
}

export enum AmlockStatus {
  ALL = "ALL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  INVALID = "INVALID",
  APPROVED_BY_AMLOCK = "APPROVED_BY_AMLOCK",
  APPROVED_BY_COMPLIANCE = "APPROVED_BY_COMPLIANCE",
  NEED_COMPLIANCE_ACTION = "NEED_COMPLIANCE_ACTION",
  REJECTED_BY_COMPLIANCE = "REJECTED_BY_COMPLIANCE",
}

export enum PEPStatus {
  ALL = "ALL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  CHECKED = "CHECKED",
  YES = "TRUE",
  NO = "FALSE",
}

export enum RouterPath {
  CUSTOMER_ROUTE = "/customers",
  APP_SETTINGS_ROUTE = "/app_settings",
  LOANS_ROUTE = "/loans",
  PAYOUT_FINANCE_ROUTE = "/payouts-finance",
  STATEMENTS_ROUTE = "/statements",
  PAYOUT_CREDIT_ROUTE = "/payouts-credit",
  REPORTS_ROUTE = "/reports",
  DASH_REPORT = "/dashboard",
  PAYMENT_ROUTE = "/payments",
  AML = "/aml",
  PEP = "/pep",
}

export enum PayoutPageType {
  CREDIT = "CREDIT",
  FINANCE = "FINANCE",
}
export const ALL = "ALL";

export const PROFILE_KEY = "profile";
export const ROLES_KEY = "roles";
export const SESSOIN_EXPIRY_KEY = "session_expiry";
export const supportEmail = "support@QuaraFinance.com";
